<script>
import Layout from "../../layouts/main";
import appConfig from "../../../../app.config";

import WidgetChart from "@/components/widgets/Widget-chart";
import PageHeader from "@/components/Page-header";
import RevenueReport from "@/components/widgets/Revenue-report";
import ProductsSales from "@/components/widgets/Products-sales";
import MarketingReports from "@/components/widgets/Marketing-reports";
import Portlet from "@/components/widgets/Portlet";
import RevenueHistory from "@/components/widgets/Revenue-history";
import Projections from "@/components/widgets/Projections";
import BaseUrl from "../../../components/constants";
import ToastConfigAlert from "../../../../ToastConfig";
import LottieLoader from "../../../components/LottieLoader";
import {
  MglMap,
  MglMarker,
  MglGeojsonLayer
} from 'vue-mapbox'

/**
 * Sales-Dashboard component
 */
export default {
  page: {
    title: "Sales-Dashboard",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    MglMap,
    MglMarker,
    MglGeojsonLayer,
    Layout,
    WidgetChart,
    PageHeader,
    RevenueReport,
    ProductsSales,
    MarketingReports,
    Portlet,
    RevenueHistory,
    Projections,
    LottieLoader
  },
  data() {
    return {
      logitude:'',
      latitude:'',
      productType :'',
      accessToken: BaseUrl.MapBoxPublicKey,
      mapStyle: "mapbox://map_style",
      geojson: {

      },
      layerId: "firstLayer",
      sourceId: "firstSource",
      markerCoordinates: [50, 50],
      center: [50, 50],
      showLoader : false,
      showLoaderTopSelling : false,
      showLoaderPromotion : false,
      categoryType:'PHARMACY',
      listOfTopSellingProducts : [],
      listOfPromotionProducts : [],
      totalRiders:0,
      totalOrders : 0,
      totalPatients : 0,
      totalDoctors : 0,
      title: "Welcome !",
      items: [
        {
          text: "Pill",
        },
        {
          text: "Dashboards",
        },
        {
          text: "",
          active: true,
        },
      ],
      tableTitle: "Orders",
      productData: [],

    };
  },

  computed : {
    widgetData() {
      if (this.totalOrders !== undefined) {
        return [
          {
            url:'/#/pharmacy/orders',
            number : `${this.totalOrders}`,
            text: "Orders",
            chartColor: "#1abc9c",
          },
          {
            url:'/#/patients',
            number: `${this.totalPatients}`,
            text: "Patients",
            chartColor: "#029F96",
          },
          {
            url:'/#/doctors',
            number: `${this.totalDoctors}`,
            text: "Doctors",
            chartColor: "#f672a7",
          },
          {
            url:'/#/riders',
            number: `${this.totalRiders}`,
            text: "Riders",
            chartColor: "#6c757d",
          },
        ];
      } else {
        return [];
      }
    },
  },
  methods : {
    getTop5Orders() {
        this.showLoader = true
         BaseUrl.AxiosBearer.get("/store/orders/get-orders?accountType=ALL&page=1&pageSize=5").then((resp) => {
           this.showLoader = false

           if(resp.data.status) {
             this.productData = []
             this.productData = resp.data.payload
           }
         }).catch((error) => {

         })
    },
     getTotalOrders() {
        BaseUrl.AxiosBearer.get("/store/orders/get-orders?accountType=ALL").then((resp) => {

          if(resp.data.status) {
             console.log("Orders : ", resp.data.payload.length)
            this.totalOrders = parseInt(resp.data.payload.length)
          }

        }).catch((error) => {
          this.$bvToast.toast(error, ToastConfigAlert)
        })
     },
    getTotalNumberOfPatients() {
      BaseUrl.AxiosBearer.get("/admin/patients").then((resp) => {

        if(resp.data.status) {
          console.log("Patients : ", resp.data.payload.length)
          this.totalPatients = parseInt(resp.data.payload.length)
        }

      }).catch((error) => {
        this.$bvToast.toast(error, ToastConfigAlert)
      })
    },
    getTotalNumberOfDoctors() {
      BaseUrl.AxiosBearer.get("/admin/doctors").then((resp) => {

        if(resp.data.status) {
          console.log("Doctors : ", resp.data.payload.length)
          this.totalDoctors = parseInt(resp.data.payload.length)
        }

      }).catch((error) => {
        this.$bvToast.toast(error, ToastConfigAlert)
      })
    },
    getTotalNumberOfRiders() {
      BaseUrl.AxiosBearer.get("/admin/riders").then((resp) => {

        if(resp.data.status) {
          console.log("Riders : ", resp.data.payload.length)
          this.totalRiders = parseInt(resp.data.payload.length)
        }

      }).catch((error) => {
        this.$bvToast.toast(error, ToastConfigAlert)
      })
    },
    getTopSellingProducts(categoryType) {
        this.showLoaderTopSelling = true;
        this.productType = categoryType === 'PHARMACY' ? 'product' :'grocery'
        BaseUrl.AxiosBearer.get(`/store/products/get-top-sellers?classification=${categoryType}&page=1&pageSize=5`).then((resp) =>{
        this.showLoaderTopSelling = false;
          if(resp.data.status) {
              this.listOfTopSellingProducts = [];
              this.listOfTopSellingProducts = resp.data.payload
          }
        }).catch((error) => {
          this.showLoaderTopSelling = false;
        })
    },
     getListOfPromotionProducts(categoryType) {
      this.showLoaderPromotion = true;
       this.productType = categoryType === 'PHARMACY' ? 'product' :'grocery'
      BaseUrl.AxiosBearer.get(`/store/products/get-promotions?classification=${categoryType}&page=1&pageSize=5`).then((resp) =>{
        this.showLoaderPromotion = false;
        if(resp.data.status) {
          this.listOfPromotionProducts = [];
          this.listOfPromotionProducts = resp.data.payload
        }
      }).catch((error) => {
        this.showLoaderPromotion = false;
      })
    },
    mapClickhandler() {
       console.log(" clicked")
    }
  },
  mounted() {
    this.getTotalOrders()
    this.getTotalNumberOfPatients()
    this.getTotalNumberOfDoctors()
    this.getTotalNumberOfRiders()
    // mapboxgl.accessToken = BaseUrl.MapBoxPublicKey;
    // this.map = new mapboxgl.Map({
    //   container: this.$refs.map,
    //   // style: 'mapbox://styles/mapbox/streets-v11', // You can change the style URL
    //   center: [0, 0], // Initial center
    //   zoom: 2, // Initial zoom level
    // });

    // Ensure that the map is fully loaded
    // this.map.on('load', () => {
    //   console.log('Map is loaded!');
    // });
  },
  created() {
    this.getTopSellingProducts(this.categoryType)
    this.getListOfPromotionProducts(this.categoryType)
    this.getTop5Orders()
    this.logitude = this.$store.getters.getCordinates.log;
    this.latitude = this.$store.getters.getCordinates.lat;
    console.log(" Log ; ", this.logitude)
    console.log(" lat ; ", this.latitude)
  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div
        v-for="widget in widgetData"
        :key="widget.text"
        class="col-xl-3 col-md-6"
      >
        <WidgetChart
          :url="widget.url"
          :number="widget.number"
          :text="widget.text"
          :chart-color="widget.chartColor"
        />
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
    
    <!-- end row -->
    <div class="row">
       <div class="col-lg-12 mb-3">
           <div class="card">
             <div class="card-body">
               <h4 class="header-title"></h4>
<!--               <p class="card-title-dsec">Example of google maps.</p>-->
               <!-- basic map -->
               <MglMap
                       container="map-test"
                       :center.sync="center"
                       :accessToken="accessToken"
                       :mapStyle="mapStyle"
               >
                 <MglMarker :coordinates.sync="markerCoordinates" color="green" />
                 <MglGeojsonLayer
                         type="fill"
                         :sourceId="sourceId"
                         :layerId="layerId"
                         :source="geojson"
                         @click="mapClickhandler"
                 />
               </MglMap>
             </div>
           </div>
         </div>
      <!-- Table -->
      <div class="col-xl-12">
        <Portlet :headertitle="tableTitle">
          <div class="card-body pt-0">
            <div>
              <lottie-loader :loading="showLoader" />
            </div>
            <div class="table-responsive mb-0">
              <table class="table table-hover table-centered mb-0">
                <thead>
                  <tr>
                    <th>Order number</th>
                    <th>Order status</th>
                    <th>Price</th>
                    <th>Delivery fee</th>
                    <th>Discount amount</th>
                    <th>Total price</th>
                    <th>Quantity</th>
                    <th>Payment method</th>
<!--                    <th>Action</th>-->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="sellingData in productData" :key="sellingData.id">
                    <td>
                      <router-link class="font-12 font-weight-bold mr-2 text-primary " style="cursor: pointer; border-bottom: 2px dotted #029F96;;" :to="'/pharmacy/order/'+sellingData.id">
                        #{{ sellingData.orderNumber }} </router-link></td>
                    <td> <span class="p-1 badge badge-soft-success"
                       :class="{
                                  'badge-soft-danger':
                                    sellingData.status === 'PENDING_CONFIRMATION',
                                  'badge-soft-primary':
                                   sellingData.status === 'APPROVED_CONFIRMATION',
                                }">{{ sellingData.status }} </span></td>
                    <td> <span class="badge badge-outline-info">GHS {{ sellingData.price }}</span> </td>
                    <td><span class="badge badge-outline-secondary">GHS {{ sellingData.deliveryPrice }}</span> </td>
                    <td><span class="badge badge-outline-danger">GHS {{ sellingData.discountedPrice }}</span></td>
                    <td><span class="badge badge-outline-primary"> GHS {{ sellingData.totalPrice }}</span></td>
                    <td> <span class="text-danger font-weight-bold">{{ sellingData.quantity }} </span></td>
                    <td> <span class="p-1 badge"
                         :class="{
                                  'badge-success': sellingData.paymentMethod === 'CASH',
                                  'badge-info': sellingData.paymentMethod === 'MOMO',
                                }">{{ sellingData.paymentMethod }} </span></td>
<!--                    <td> <router-link class="btn btn-primary mr-2" style="cursor: pointer" :to="'/pharmacy/order/'+sellingData.id"> <i class="mdi mdi-eye mx-1"></i>View</router-link></td>-->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Portlet>
      </div>
    </div>
    <!-- end row -->
    <div class="row">
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <div>
              <lottie-loader :loading="showLoaderTopSelling" />
            </div>
             <div class="table-responsive mb-0">
               <b-dropdown class="float-right" variant="black" right toggle-class="arrow-none card-drop">
                 <template v-slot:button-content>
                   <i class="mdi mdi-dots-horizontal"></i>
                 </template>
                 <a href="javascript:void(0);" @click="getTopSellingProducts('PHARMACY')" class="dropdown-item">Pharmacy</a>
                 <!-- item-->
                 <a href="javascript:void(0);" @click="getTopSellingProducts('GROCERIES')" class="dropdown-item">Groceries</a>
               </b-dropdown>
               <h4 class="header-title mb-3">Top 5 selling products</h4>
              <table class="table table-borderless table-hover table-centered m-0">
                <thead class="thead-light">
                <tr>
                  <th>Product Name</th>
                  <th>Image</th>
                  <th>Price</th>
<!--                  <th>Action</th>-->
                </tr>
                </thead>
                <tbody>
                <tr v-for="data in listOfTopSellingProducts" :key="data.id">
                  <td><router-link class="font-12 font-weight-bold mr-2 text-primary " style="cursor: pointer; border-bottom: 2px dotted #029F96;;" :to="`/${productType}/`+data.id">{{ data.productName }}</router-link></td>
                  <td> <img :src="data.mainImage.imageUrl" alt="product" width="50" height="50" /></td>
                  <td> <span class="badge badge-outline-primary">{{ data.sellingPrice }} </span></td>
<!--                  <td>-->
<!--                    <a href="javascript: void(0);" class="btn btn-xs btn-secondary">-->
<!--                      <i class="mdi mdi-pencil"></i>-->
<!--                    </a>-->
<!--                  </td>-->
                </tr>
                </tbody>
              </table>
        </div>
          </div>
        </div>
      </div>
      <!-- end col -->
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <div>
              <lottie-loader :loading="showLoaderPromotion" />
            </div>
            <div class="table-responsive mb-0">
              <b-dropdown class="float-right" variant="black" right toggle-class="arrow-none card-drop">
                <template v-slot:button-content>
                  <i class="mdi mdi-dots-horizontal"></i>
                </template>
                <a href="javascript:void(0);" @click="getListOfPromotionProducts('PHARMACY')" class="dropdown-item">Pharmacy</a>
                <!-- item-->
                <a href="javascript:void(0);" @click="getListOfPromotionProducts('GROCERIES')" class="dropdown-item">Groceries</a>
              </b-dropdown>
              <h4 class="header-title mb-3">Promotions</h4>
              <table class="table table-borderless table-hover table-centered m-0">
                <thead class="thead-light">
                <tr>
                  <th>Product Name</th>
                  <th>Image</th>
                  <th>Price</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="data in listOfPromotionProducts" :key="data.id">
                  <td> <router-link class="font-12 font-weight-bold mr-2 text-primary " style="cursor: pointer; border-bottom: 2px dotted #029F96;;" :to="`/${productType}/`+data.id">{{ data.productName }} </router-link></td>
                  <td> <img :src="data.mainImage.imageUrl" alt="product" width="50" height="50" /></td>
                  <td> <span class="badge badge-outline-primary">{{ data.sellingPrice }} </span></td>

                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style>
  .map-container {
    width: 100%;
    height: 450px;
  }
</style>

